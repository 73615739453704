import axios from 'axios';
import { wixAxiosConfig } from '@wix/wix-axios-config';

let isWixAxiosSetuped = false;

function maybeConfigWixAxios(baseURL: string) {
  if (!isWixAxiosSetuped) {
    wixAxiosConfig(axios, { baseURL });
    isWixAxiosSetuped = true;
  }
}

interface AjaxJsonParams {
  baseURL: string;
  url: string;
  authorizationHeader?: unknown;
  onSuccess: Function;
  instance?: string;
  payload?: unknown;
}

export function getAjaxJson({ baseURL, url, authorizationHeader, onSuccess }: AjaxJsonParams) {
  maybeConfigWixAxios(baseURL);
  return axios({
    url,
    method: 'GET',
    headers: { Authorization: authorizationHeader },
  })
    .then((res) => onSuccess(res.data))
    .catch((e) => {
      console.log('error fetching data', e.message);
      onSuccess({});
    });
}

export function postAjaxJson({ baseURL, url, instance, payload, onSuccess }: AjaxJsonParams) {
  maybeConfigWixAxios(baseURL);
  return axios({
    url,
    method: 'POST',
    headers: { Authorization: instance, 'Content-Type': 'application/json' },
    data: JSON.stringify(payload),
  })
    .then((res) => onSuccess(res.data))
    .catch((e) => {
      console.log('error fetching data', e.message);
      onSuccess({});
    });
}
