interface GetOptions {
  params?: Record<string, string | string[]>;
}

export interface HTTPClient {
  get: <T>(url: string, options?: GetOptions) => Promise<{ data: T }>;
}

interface Config {
  essentials?: {
    appEssentials?: {
      httpClient: HTTPClient;
    };
  };
}

export const initHttpClient = (config: Config) => {
  return config.essentials?.appEssentials?.httpClient ?? null;
};
