import { Member, MemberData, RolesMap, WixCodeApi } from '../common/types';
import { USER_NAME_PATTERN } from '../constants';
import { logError } from '../utils/monitoring';
import { getAjaxJson, postAjaxJson } from './ajax';
import { getMemoryStorage } from './memory-storage';

const CURRENT_USER_SLUG_STORAGE_KEY = 'current-user-slug';
const CURRENT_USER_ID_STORAGE_KEY = 'current-user-id';

export function init(wixCodeApi: WixCodeApi) {
  let viewedUser: Member, currentUser: Member, userRoles: RolesMap;

  function fetchMenuCounters(user: Member, santaMembersInstance: string) {
    if (!user || user.loggedIn === false) {
      return Promise.resolve({});
    }
    return new Promise((resolve, reject) => {
      const userId = user && user.id;
      if (userId && santaMembersInstance) {
        fetchNumbers(userId, santaMembersInstance, (response: unknown) => {
          resolve(response);
        });
      } else {
        reject(new Error('No user to get menu counters by'));
      }
    });
  }

  function fetchNumbers(userId: string, instance: string, successFn: Function) {
    const baseURL = wixCodeApi.window.viewMode === 'Site' ? wixCodeApi.location.baseUrl : 'https://www.wix.com/';
    return getAjaxJson({
      baseURL,
      url: `${baseURL}/_api/santa-members-server/temporary/members/${userId}/numbers`,
      authorizationHeader: instance,
      onSuccess: successFn,
    });
  }

  function fetchRoles(viewedUserId: string, loggedInUserId: string, instance: string): Promise<RolesMap> {
    return new Promise((resolve) => {
      const baseURL = wixCodeApi.window.viewMode === 'Site' ? wixCodeApi.location.baseUrl : 'https://www.wix.com/';
      const payload = [];
      if (viewedUserId) {
        payload.push(viewedUserId);
      }
      if (loggedInUserId) {
        payload.push(loggedInUserId);
      }
      postAjaxJson({
        baseURL,
        url: `${baseURL}/_api/santa-members-server/temporary/members/roles`,
        instance,
        payload,
        onSuccess: (roles: RolesMap) => {
          resolve(roles);
        },
      });
    });
  }

  function replaceUserPatternWithSlug(url: string, user: Member) {
    return url.replace(USER_NAME_PATTERN, user.slug).replace(encodeURI(USER_NAME_PATTERN), user.slug);
  }

  function getViewedUser() {
    return viewedUser ?? {};
  }

  function getCurrentUser() {
    return currentUser ?? {};
  }

  function getRoles() {
    return userRoles ?? {};
  }

  function setRoles(roles: RolesMap) {
    userRoles = roles;
  }

  function setViewedUser(userData: Member) {
    if (userData) {
      viewedUser = userData;
    }
  }

  function setCurrentUser(userData: MemberData, instance: string) {
    return Promise.resolve()
      .then(() => getCurrentUserSlug(userData, instance))
      .then((slug: string) => {
        currentUser = {
          id: userData.id,
          loggedIn: userData.loggedIn,
          slug,
        };
      });
  }

  function fetchCurrentUserSlug(instance: string) {
    const baseURL = wixCodeApi.location.baseUrl;
    return new Promise((resolve) =>
      getAjaxJson({
        baseURL,
        url: baseURL + '/_api/members/v1/members/my',
        authorizationHeader: instance,
        onSuccess: (m: any) => resolve(m.member?.profile?.slug),
      }),
    );
  }

  function getCurrentUserSlug(userData: MemberData, instance: string) {
    const memoryStorage = getMemoryStorage();
    const storageSlug = memoryStorage.getItem(CURRENT_USER_SLUG_STORAGE_KEY);
    const currentUserId = memoryStorage.getItem(CURRENT_USER_ID_STORAGE_KEY);

    if (storageSlug && currentUserId === userData.id) {
      return storageSlug;
    }

    if (!currentUserId || currentUserId !== userData.id) {
      memoryStorage.setItem(CURRENT_USER_ID_STORAGE_KEY, userData.id);
    }

    if (userData.loggedIn && userData.getSlug) {
      // Calling manually instead of userData.loggedIn.getSlug to not depend on their implementation
      // This was applied as a hotfix because of broken userData.loggedIn.getSlug implementation
      if (wixCodeApi.window.viewMode === 'Site') {
        return fetchCurrentUserSlug(instance)
          .then((slug: string) => {
            const finalSlug = slug || userData.id;
            memoryStorage.setItem(CURRENT_USER_SLUG_STORAGE_KEY, finalSlug);
            return finalSlug;
          })
          .catch(() => logError('Error while fetching current user slug', { userDataId: userData.id }));
      }

      // For Preview mode we can't do the request so using default
      return userData.getSlug();
    }
    return userData.id;
  }

  return {
    getCurrentUser,
    setCurrentUser,
    getViewedUser,
    setViewedUser,
    fetchRoles,
    getRoles,
    setRoles,
    fetchMenuCounters,
    replaceUserPatternWithSlug,
  };
}

export type UserService = ReturnType<typeof init>;
